<template>
  <div class="w-block__heading">
    <!-- heading block -->
  </div>
</template>

<script>
export default {
  components: {},
}
</script>
