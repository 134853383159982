/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '61f948b897d64f152d808ff8',
          i18n: {
            fr: {
              title: 'Editions mensuelles',
            },
          },
          heading: {
            color: '#e84b23',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
        },
        {
          type: 'tag',
          tagId: '61f948c497d64f47f3808ff9',
          i18n: {
            fr: {
              title: 'Editions spéciales',
            },
          },
          heading: {
            color: '#e84b23',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
        },
        {
          type: 'tag',
          tagId: '5c5ac1831253519a860f6cc6',
          i18n: {
            fr: {
              title: 'Emissions Next Year',
            },
          },
          heading: {
            color: '#e84b23',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
        },
        {
          type: 'tag',
          tagId: '61f948e397d64f6586808ffa',
          i18n: {
            fr: {
              title: 'Editions du CSE',
            },
          },
          heading: {
            color: '#e84b23',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
        },
      ],
      _event: new Date('2022-02-14T13:59:59.999Z'),
      _eventSpan: 1 * 60 * 60 * 1000, // 1h in ms
    },
  ],
}
